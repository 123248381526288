<template>
  <div class="error-page">
    <div class="center">
      <img class="error-img" src="./img/error-icon.svg" alt="error-icon">
      <div class="error-msg">{{ message }}</div>
      <div class="nav-link">
        <a href="/">Go Back Home</a>
      </div>
    </div>
  </div>
</template>

<script>
import lodash from 'lodash'

export default {
  props: {
    error: {
      type: Object,
    }
  },
  data() {
    return {
      defaultMsg: "Sorry this page isn't available right now"
    }
  },
  computed: {
    message() {
      let errorMsg = lodash.get(this.error, 'errorMessageEn');
      return errorMsg || this.defaultMsg
    }
  }
}
</script>

<style scoped>
.error-page {
  height: 100vh;
}

.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.nav-link {
  margin: 10px 0;
  text-align: center;
}

.nav-link a {
  text-decoration: none;
  color: #e2136e;
  font-weight: 500;
}

.error-msg {
  margin-top: 30px;
  text-align: center;
  font-size: 34px;
  font-weight: 500;
  font-family: Roboto sans-serif;
}

@media only screen and (max-width: 600px) {
  .error-img {
    width: 250px;
  }

  .error-msg {
    font-size: 14px;
  }
}

@media only screen and (min-width: 600px) {
  .error-img {
    width: 600px;
  }

  .error-msg {
    font-size: 24px;
  }
}
</style>